/* Manifest Create, Vue Component */
<template>
  <v-card v-if="entity && !loading" id="manifest-create" class="entity-create">
    <v-card-title>Create Manifest</v-card-title>

    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <v-alert v-if="serverErrorMessage"
               type="error">
        {{ serverErrorMessage }}
      </v-alert>
    </v-card-text>

    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <v-row class="align-center pb-5">
        <v-col>
          <v-btn color="primary"
                 class="me-3"
                 @click="Save">
            <v-icon size="18"
                    class="me-1">
              {{ icons.mdiContentSaveOutline  }}
            </v-icon>
            <span>Open</span>
          </v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn color="secondary"
                 class="me-3"
                 @click="Back">
            <v-icon size="18"
                    class="me-1">
              {{ icons.mdiChevronLeft }}
            </v-icon>
            <span>Back</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>

    <v-form ref="form">
      <v-simple-table dense>
        <thead>
          <tr>
            <th colspan="2">MANIFEST DETAILS</th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>Destination</td>
            <td><CountryLookup :filter="{filter:{CanManifest: true}}" v-model="entity.CountryId" :rules="[validators.required]" :error-messages="serverErrors.filter(x => x.field === 'CountryId').map(x => x.error)" /></td>
          </tr>

          <!--<tr>
            <td>Date</td>
            <td><DateField v-model="entity.ManifestDate" :rules="[validators.required]" :error-messages="serverErrors.filter(x => x.field === 'ManifestDate').map(x => x.error)" /></td>
          </tr>-->


        </tbody>
      </v-simple-table>
    </v-form>

  </v-card>
</template>

<script>
  import store from '@/store'
  import { useRouter } from '@core/utils'
  import * as Display from '@/datahelpers/displayTypes'
  import * as FrameworkValidators from '@core/utils/validation'
  import * as Validators from '@/datahelpers/validation'
  import DateField from '@/views/helpers/DateField'
  import CountryLookup from '@/views/country/CountryLookup'
  import UserLookup from '@/views/user/UserLookup'

  import {
    mdiContentSaveOutline,
    mdiChevronLeft
  } from '@mdi/js'
  import { ref, watch, computed, expose } from '@vue/composition-api'

  export default {
    components: {
      DateField,
      CountryLookup,
      UserLookup
    },
    setup() {

      const { route, router } = useRouter();

      const entity = ref({});
      const loading = ref(false);
      const form = ref(null);
      const serverErrors = ref([]);
      const serverErrorMessage = ref(null);

      const Save = function (e) {

        serverErrorMessage.value = null;
        serverErrors.value = [];

        const valid = form.value.validate();

        if (!valid) {
          return;
        }

        loading.value = true;

        store
          .dispatch('app/createManifest', {
            entity: entity.value
          })
          .then(response => {
            loading.value = false;
            router.push({ name: 'manifest-edit', params: { id: response.data.Id } });
          })
          .catch((error) => {
            loading.value = false;
            if (error.error) {
              if (error.message) serverErrorMessage.value = error.message;
              if (error.errors) serverErrors.value = error.errors
            } else {
              console.log(error);
            }
          });
      };

      const Back = () => {
        router.go(-1);
      };

      const validators = {
        ...FrameworkValidators,
        ...Validators
      };

      return {
        Back,
        form,
        Save,
        loading,
        entity,
        Display,
        icons: {
          mdiContentSaveOutline,
          mdiChevronLeft
        },
        validators,
        serverErrorMessage,
        serverErrors
      };
    }
  }
</script>

<style lang="scss" scoped>
  .entity-create::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td:last-child {
    padding-right: 0;
  }

  .entity-create::v-deep .v-data-table th:last-child {
    width: 66%;
  }

  .entity-create::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: transparent;
  }

  .entity-create::v-deep .v-input:not(.error--text) .v-messages {
    min-height: unset;
  }

  .entity-create::v-deep .v-input .v-messages.error--text {
    margin-bottom: 8px;
  }

  .entity-create::v-deep .v-input:not(.error--text) .v-text-field__details {
    min-height: unset;
    margin-bottom: 0;
  }

  .entity-create::v-deep .v-input:not(.error--text) .v-input__slot {
    margin: 0;
    border-radius: 0;
  }

  .entity-create::v-deep .v-input--selection-controls {
    margin: 10px;
  }
</style>

